import React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import { useI18next } from 'gatsby-plugin-react-i18next'
import { StaticImage } from 'gatsby-plugin-image'
import { RiAppleFill } from 'react-icons/ri'
import { MdAndroid } from 'react-icons/md'

export default function Bible() {
  const { t } = useI18next()
  return (
    <Layout>
      <StaticImage
        src="../assets/images/bible.jpeg"
        alt="studying the Bible"
        className="w-full max-h-[50vh]"
        placeholder="tracedSVG"
      />
      <div className="divide-y-2 md:w-1/2 mx-auto px-2 md:px-0">
        <section className="flex flex-col items-center justify-center py-16 gap-4">
          <div className="flex gap-4 items-center justify-center">
            <StaticImage
              src="../assets/images/youversion.png"
              alt=""
              className="w-12"
            />
            <h1 className="text-2xl md:text-4xl">{t('onlineBible')}</h1>
          </div>
          <div className="mt-4 flex items-center justify-center gap-4 text-violet-50">
            <a
              href="https://www.bible.com/bible/116/LUK.1.NLT"
              className="w-44 rounded px-4 py-3 text-xl border-2 bg-gradient-to-br from-sky-700 to-violet-900 flex gap-2 items-center justify-center hover:scale-105"
            >
              <span>🇬🇧 {t('english')}</span>
            </a>
            <a
              href="https://www.bible.com/bible/1683/LUK.1.HINOVBSI"
              className="w-44 rounded px-4 py-3 text-xl border-2 bg-gradient-to-br from-sky-700 to-violet-900 flex gap-2 items-center justify-center hover:scale-105"
            >
              <span>🇮🇳 {t('hindi')}</span>
            </a>
          </div>
        </section>
        <section className="flex flex-col items-center justify-center py-16 gap-4">
          <div className="flex gap-4 items-center justify-center">
            <StaticImage
              src="../assets/images/youversion.png"
              alt=""
              className="w-12"
            />
            <h1 className="text-2xl md:text-4xl">{t('youVersion')}</h1>
          </div>
          <div className="mt-4 flex items-center justify-center gap-4 text-violet-50">
            <a
              href="https://play.google.com/store/apps/details?id=com.sirma.mobile.bible.android"
              className="w-44 rounded px-4 py-3 text-xl border-2 bg-gradient-to-br from-sky-700 to-violet-900 flex gap-2 items-center justify-center hover:scale-105"
            >
              <MdAndroid />
              <span>{t('googlePlay')}</span>
            </a>
            <a
              href="https://apps.apple.com/us/app/bible/id282935706"
              className="w-44 rounded px-4 py-3 text-xl border-2 bg-gradient-to-br from-sky-700 to-violet-900 flex gap-2 items-center justify-center hover:scale-105"
            >
              <RiAppleFill />
              <span>{t('appleStore')}</span>
            </a>
          </div>
        </section>
        <section className="flex flex-col items-center justify-center py-16 gap-4">
          <div className="flex gap-4 items-center justify-center">
            <StaticImage
              src="../assets/images/bible-is-logo.png"
              alt=""
              className="w-12"
            />
            <h1 className="text-2xl md:text-4xl">{t('audioVideoBible')}</h1>
          </div>
          <div className="mt-4 flex items-center justify-center gap-4 text-violet-50">
            <a
              href="https://live.bible.is/bible/NPINRV/LUK/1"
              className="w-44 rounded px-4 py-3 text-xl border-2 bg-gradient-to-br from-sky-700 to-violet-900 flex gap-2 items-center justify-center hover:scale-105"
            >
              <span>🇬🇧 {t('english')}</span>
            </a>
            <a
              href="https://live.bible.is/bible/HINHNV/LUK/1"
              className="w-44 rounded px-4 py-3 text-xl border-2 bg-gradient-to-br from-sky-700 to-violet-900 flex gap-2 items-center justify-center hover:scale-105"
            >
              <span>🇮🇳 {t('hindi')}</span>
            </a>
          </div>
        </section>
        <section className="flex flex-col items-center justify-center py-16 gap-4">
          <div className="flex gap-4 items-center justify-center">
            <StaticImage
              src="../assets/images/bible-is-logo.png"
              alt=""
              className="w-12"
            />
            <h1 className="text-2xl md:text-4xl">{t('bibleIs')}</h1>
          </div>
          <div className="mt-4 flex items-center justify-center gap-4 text-violet-50">
            <a
              href="https://play.google.com/store/apps/details?id=com.faithcomesbyhearing.android.bibleis"
              className="w-44 rounded px-4 py-3 text-xl border-2 bg-gradient-to-br from-sky-700 to-violet-900 flex gap-2 items-center justify-center hover:scale-105"
            >
              <MdAndroid />
              <span>{t('googlePlay')}</span>
            </a>
            <a
              href="https://apps.apple.com/us/app/bible/id378075859"
              className="w-44 rounded px-4 py-3 text-xl border-2 bg-gradient-to-br from-sky-700 to-violet-900 flex gap-2 items-center justify-center hover:scale-105"
            >
              <RiAppleFill />
              <span>{t('appleStore')}</span>
            </a>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query BiblePageQuery($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "bible"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
